/* Container for all rows */

@font-face {
  font-family: 'MyCustomFont13';
  src: url('../../public/fonts/CooperLtBT-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

.brand-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  position: relative;
  padding: 20px 0;
  gap: 10px;
}

/* Row styling */
.brand-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;
  gap: 50px;
  margin-bottom: 20px;
  margin-left: 20%;
}

/* Individual brand item */
.brand-item {
  flex-shrink: 0;
}

.brand-box {
  width: 250px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Logo styling */
.brand {
  width: 140px;
  height: auto;
  margin-right: 5px;
}

/* Section styling */
.brands-section {
  text-align: center;
  margin-bottom: 40px;
}

/* Heading styling */
.brands-heading {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  font-family: 'MyCustomFont13', sans-serif;
  margin-bottom: 120px;
  margin-top: 70px;
  color: #005F9E;
  position: relative;
}

.brands-heading .word {
  position: relative;
  display: inline-block;
  padding: 0 1rem; /* Add padding between words */
  overflow: hidden;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.brands-heading .word::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(240, 189, 96, var(--highlight-opacity, 0));
  z-index: -1;
  transition: background-color 0.3s ease;
}

.brands-heading .word:hover::before {
  background-color: rgb(234, 194, 120); /* Highlight fully on hover */
}

/* Animation for left-to-right movement */
@keyframes move-left-to-right {
  0% {
    transform: translateX(100%);
  }
  30% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(0%); /* Pause longer in the middle */
  }
  70% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Animation for right-to-left movement */
@keyframes move-right-to-left {
  0% {
    transform: translateX(-100%);
  }
  30% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(0%); /* Pause longer in the middle */
  }
  70% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Apply the animation to the rows */
.row-left-to-right {
  animation: move-left-to-right 6s ease-in-out infinite;
}

.row-right-to-left {
  animation: move-right-to-left 6s ease-in-out infinite;
}

/* Responsiveness for smaller screens */
@media (max-width: 1024px) {
  .brand-row {
    gap: 30px;
    margin-left: 10%;
  }
  .brand-box {
    width: 200px;
    height: 100px;
  }
  .brand {
    width: 100px;
  }
}

@media (max-width: 768px) {
  .brand-row {
    gap: 20px;
    margin-left: 5%;
  }
  .brand-box {
    width: 150px;
    height: 75px;
  }
  .brand {
    width: 75px;
  }
}

@media (max-width: 480px) {
  .brand-row {
    flex-direction: column;
    gap: 15px;
    margin-left: 0;
  }
  .brand-box {
    width: 100px;
    height: 50px;
  }
  .brand {
    width: 50px;
  }
  .brands-heading {
    font-size: 1.5rem;
  }
}
