/* General Container */
.about-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px 20px;
  background: linear-gradient(to bottom right, rgba(240, 248, 255, 0.4), rgba(175, 238, 238, 0.6));
}

/* About Section */
.about-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 92%; /* Slightly reduced width */
  height: 60vh;
  margin: 0 auto;
  padding: 60px 40px;
  border-radius: 26px; /* Curved edges */
  background: rgba(255, 255, 255, 0.3); /* Glass-like transparency */
  backdrop-filter: blur(24px); /* Frosted glass effect */
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2); /* Depth shadow */
  transition: transform 0.6s ease, opacity 0.6s ease;
  opacity: 0; /* Hidden by default for animation */
  transform: translateY(80px);
  background-size: cover; /* Ensure proportional scaling */
  background-position:center; /* Position the image to show the head */
  background-repeat: no-repeat;
}

/* Reduce Image Brightness for Transparency */
.about-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1); /* Subtle white overlay */
  backdrop-filter: blur(7px); /* Additional blur for the image */
  z-index: 1; /* Keep behind text */
  pointer-events: none; /* Prevent interaction */
}

/* Visible Class for Animation */
.about-section.visible {
  opacity: 1;
  transform: translateY(0); /* Smooth slide-up animation */
}

/* Reverse Layout for Alternating Sections */
.about-section.reverse {
  flex-direction: row-reverse;
}

/* Text Content Styling */
.text-content {
  z-index: 2;
  text-align: center;
  flex: 1;
  position: relative;
  color: rgb(239, 238, 238); /* Make fonts black */
  transition: transform 0.3s ease, font-size 0.3s ease; /* Hover animation */
}

.text-content h4 {
  font-size: 18px;
  color: #8B572A; /* Accent color */
  margin-bottom: 10px;
}

.text-content h2 {
  font-size: 42px;
  color: rgba(2, 2, 67, 0.969);
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 260px;
  font-family: 'MyCustomFont13', sans-serif;
}

.text-content p {
  font-size: 20px;
  line-height: 1.5;
  color: rgba(19, 223, 255, 0.969); /* Subtle black-gray for readability */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
  font-family: 'MyCustomFont13', sans-serif;
  font-weight:bolder
}

.text-content:hover {
  transform: scale(1.1); /* Expand on hover */
  font-size: 1.2em;
}

/* Responsive Design */

/* Tablet */
/* Mobile-specific styles */
@media (max-width: 768px) {
  .about-container {
    padding: 20px 10px; /* Reduce padding for smaller screens */
    gap: 20px; /* Reduce gap between sections */
  }

  .about-section {
    flex-direction: column; /* Stack content vertically */
    width: 100%; /* Full width for mobile */
    height: auto; /* Auto height to fit content */
    padding: 20px; /* Reduce padding */
    border-radius: 16px; /* Slightly smaller rounded corners */
    margin: 0 auto; /* Center the section */
    gap: 10px; /* Reduce gap between elements */
  }

  /* Reduce Image Brightness for Transparency */
  .about-section::before {
    backdrop-filter: blur(5px); /* Slightly reduce blur for performance */
  }

  /* Text Content Styling for Mobile */
  .text-content {
    text-align: center; /* Center-align text */
    padding: 10px; /* Add padding for better spacing */
  }

  .text-content h4 {
    font-size: 16px; /* Smaller font size for mobile */
    margin-bottom: 8px; /* Reduce margin */
  }

  .text-content h2 {
    font-size: 24px; /* Smaller font size for mobile */
    margin-top: 20px; /* Adjust margin for better spacing */
    margin-bottom: 10px; /* Reduce margin */
  }

  .text-content p {
    font-size: 16px; /* Smaller font size for mobile */
    line-height: 1.4; /* Adjust line height for readability */
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7); /* Slightly reduce shadow */
  }

  /* Hover Effects for Mobile */
  .text-content:hover {
    transform: none; /* Disable hover effects on mobile */
    font-size: 16px; /* Reset font size */
  }

  /* Reverse Layout for Alternating Sections */
  .about-section.reverse {
    flex-direction: column; /* Stack content vertically */
  }
}