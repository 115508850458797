.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    font-size: 24px; /* Adjust size as needed */
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
    z-index: 2000;
  }
  
  .scroll-to-top-button:hover {
    background-color: #F5F5F5;
  }
  