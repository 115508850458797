/* Custom Fonts */
@font-face {
  font-family: 'MyCustomFont';
  src: url('../../public/fonts/AnkhSanctuary-PVK0B.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyCustomFont10';
  src: url('../../public/fonts/Lucky\ Blues.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'MyCustomFont13';
  src: url('../../public/fonts/CooperLtBT-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyCustomFont11';
  src: url('../../public/fonts/Ragna.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyCustomFont12';
  src: url('../../public/fonts/Best\ Work.otf');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'MyCustomFont5';
  src: url('../../public/fonts/Chopsic-K6Dp.ttf') format('truetype');
}

@font-face {
  font-family: 'MyCustomFont4';
  src: url('../../public/fonts/Acetone-w1gn6.ttf') format('truetype');
}

@font-face {
  font-family: 'MyCustomFont6';
  src: url('../../public/fonts/Cartesian-4B576.ttf') format('truetype');
}




/* Home Page Container */
.homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-image: url('../Assets/background1.jpg');
  background-repeat:no-repeat;/* Blue background */
  background-size: cover;

  min-height: 100vh;
  gap: 20px; /* Space between sections */
  position: relative;
}

/* Combined Layout for Info Box and 3D Model */
.info-model-container {
  display: flex;
  justify-content: center; /* Center the container */
  align-items: flex-start;
  
  width: 100%;
  max-width: 1200px;
  position: relative; /* Ensure proper placement of the model overlay */
  
}

/* Info Box Styling */
/* Info Box Styling */
.info-box {
  width: 80%; /* Wider info box */
  height: 400px; /* Increased height */
  background:rgba(255, 255, 255, 0.5); /* Glass effect */
  backdrop-filter: blur(25px); /* Enhanced blur effect */
  padding: 0px; /* Increased padding for text */
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3); /* Stronger shadow */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: 'MyCustomFont12', sans-serif;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.info-box:hover {
  transform: translateY(-5px); /* Subtle lift effect on hover */
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.4); /* Enhanced shadow on hover */
}
/* Info Box Heading Styles */
.info-box h1 {
  font-size: 2.8rem; /* Larger heading */
  color: #124278;;
  margin-bottom: 15px;
  margin-top: 2px;
  font-family: 'MyCustomFont13', sans-serif;
  text-transform: uppercase; /* Uppercase text for emphasis */
}
.info-box h2 {
  font-size: 1.5rem;
  color: #4a4a4a;
  margin-bottom: 10px;
  margin-top: 50%;
  font-family: 'MyCustomFont13', sans-serif;
  font-style: italic; /* Italicized subtitle */
}
/* Info Box Paragraph */
.info-box p {
  font-size: 1.5rem;
  color: #4a4a4a;
  line-height: 1.8;
  margin: 0;
  font-family: 'MyCustomFont13', sans-serif;
  transition: color 0.3s ease;
}
.info-box p:hover {
  color: #8B572A;
   /* Subtle color change on hover */
}

.info-box h1:hover {
  color: #4a4a4a;
}

/* Info Box Button */
.info-box button {
  background-color: #F57C00;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 15px;
  font-size: 1.2rem;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-family: 'MyCustomFont5', sans-serif;
}

.info-box button:hover {
  background-color: #8B572A;
  transform: scale(1.05); /* Slight scale effect */
}



/* 3D Model Overlay in the Top-Right Corner */
.model-container {
  position: absolute; /* Overlay positioning */
  top: 150px; /* Slightly above the info box */
  right: -100px; /* Positioned to the right */
  width: 400px; /* Larger size */
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Behind the info box */
  pointer-events: none; /* Prevent interaction with the model */
}

/* Insurance Section */
.insurance-section {
  width: 100%; /* Align width with info box */
  max-width: 1000px;
  height: 100px;
  background: rgba(173, 216, 230, 0.4); /* Glass effect */
  backdrop-filter: blur(20px); /* Subtle blur */
  margin-top: 10px; /* Reduced gap between info box and insurance section */
  padding: 10px 1px; /* Comfortable padding */
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
}

/* Insurance Section Heading */
.insurance-section h3 {
  margin-top: -2px;
  font-size: 1.5rem;
  color: #4a4a4a;
  margin-bottom: 20px;
  font-family: 'MyCustomFont13',sans-serif;
}

/* Insurance Logos */
.insurance-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.insurance-logos img {
  max-height: 50px;
  object-fit: contain;
  filter: grayscale(100%);
  transition: filter 0.3s ease;

  @media (max-width: 768px) {
    max-height: 40px;
  }
}

.insurance-logos img:hover {
  filter: grayscale(0);
}

/* Extra Small Devices (576px and below, e.g., iPhone SE, Galaxy S8) */
@media only screen and (max-width: 576px) {
  .info-box {
    width: 90%;
    height: auto;
    padding: 15px;
  }
  .info-box h1 {
    font-size: 1.8rem;
  }
  .info-box h2 {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  .info-box p {
    font-size: 1rem;
  }
  .model-container {
    margin-top: 270px; /* Pushed further down */
    right: 3px; /* Pushed further to the right corner */
    left: auto;
    transform: none;
    width: 300px;
    height: 300px;
  }
  .insurance-section {
    width: 95%;
    padding: 15px; /* Increased padding for better spacing */
    margin-top: 120px; /* Added more margin to separate it from the model */
  }
  .insurance-logos {
    display: flex;
    flex-wrap: wrap;
    gap: 15px; /* Added space between logos */
  }
  .insurance-logos img {
    max-height: 50px;
    margin-top: 25px;
   
  }
}

/* Small Phones (360px wide, e.g., Galaxy S22, Pixel 5) */
@media only screen and (max-width: 360px) {
  .info-box {
    width: 95%;
    height: auto;
    padding: 10px;
  }
  .info-box h1 {
    font-size: 1.5rem;
  }
  .info-box h2 {
    font-size: 1rem;
  }
  .info-box p {
    font-size: 0.9rem;
  }
  .model-container {
    margin-top: 80px; /* Pushed further down */
    width: 250px;
    height: 250px;
  }
  .insurance-section {
    width: 90%;
    padding: 18px; /* Increased padding */
    margin-top: 30px; /* Increased spacing */
  }
  .insurance-logos {
    display: flex;
    flex-wrap: wrap;
    gap: 15px; /* Added space between logos */
  }
  .insurance-logos img {
    max-height: 35px;
  }
}

/* Medium Phones (375px wide, e.g., iPhone 12/13/14) */
@media only screen and (min-width: 361px) and (max-width: 375px) {
  .info-box {
    width: 90%;
    padding: 15px;
  }
  .info-box h1 {
    font-size: 1.6rem;
  }
  .info-box h2 {
    font-size: 1.1rem;
  }
  .model-container {
    margin-top: 40px; /* Pushed further down */
    width: 280px;
    height: 280px;
  }
  .insurance-section {
    width: 90%;
    padding: 15px; /* Increased padding */
    margin-top: 30px; /* Increased spacing */
  }
  .insurance-logos {
    display: flex;
    flex-wrap: wrap;
    gap: 15px; /* Added space between logos */
  }
  .insurance-logos img {
    max-height: 38px;
  }
}

/* Large Phones (390px wide, e.g., iPhone 14 Pro) */
@media only screen and (min-width: 376px) and (max-width: 390px) {
  .info-box {
    width: 85%;
    padding: 20px;
  }
  .info-box h1 {
    font-size: 1.8rem;
  }
  .model-container {
    margin-top: 40px; /* Pushed further down */
    width: 300px;
    height: 300px;
  }
  .insurance-section {
    width: 85%;
    padding: 18px; /* Increased padding */
    margin-top: 30px; /* Increased spacing */
  }
  .insurance-logos {
    display: flex;
    flex-wrap: wrap;
    gap: 15px; /* Added space between logos */
  }
  .insurance-logos img {
    max-height: 40px;
  }
}

/* Extra-Large Phones (428px wide, e.g., Pixel 6 Pro) */
@media only screen and (min-width: 391px) and (max-width: 428px) {
  .info-box {
    width: 80%;
    padding: 25px;
  }
  .info-box h1 {
    font-size: 2rem;
  }
  .model-container {
    margin-top: 40px; /* Pushed further down */
    width: 320px;
    height: 320px;
  }
  .insurance-section {
    width: 80%;
    padding: 20px; /* Increased padding */
    margin-top: 30px; /* Increased spacing */
  }
  .insurance-logos {
    display: flex;
    flex-wrap: wrap;
    gap: 15px; /* Added space between logos */
  }
  .insurance-logos img {
    max-height: 45px;
  }
}
