/* ContactUs.css */
.main-container {
  background: linear-gradient(to bottom right, rgba(240, 248, 255, 0.4), rgba(175, 238, 238, 0.6));
}
.contact-us-container {
    max-width: 800px;
    margin: 5px auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .contact-us-title {
    font-size: 2.5rem;
    color: #004488;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .contact-us-description {
    text-align: center;
    color: #555555;
    margin-bottom: 30px;
  }
  
  .contact-us-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    font-size: 1rem;
    color: #333333;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #004488;
  }
  
  .submit-button {
    padding: 15px;
    background-color: #004488;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #003366;
  }
  