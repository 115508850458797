/* Page Container */
.services-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to bottom right, rgba(240, 248, 255, 0.4), rgba(175, 238, 238, 0.6));
  padding: 20px 20px; /* Reduced top padding to bring content closer to the navbar */
  min-height: 100vh;
}
.section-heading {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  font-family: 'MyCustomFont13',sans-serif;
  color: #f57c00; /* Darker teal for contrast */
  margin-top: 5px; /* Removed top margin */
  margin-bottom: 30px; /* Keep a slight gap below */
}


/* Service Container */
.service-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
  gap: 30px;
  width: 100%;
  font-family: 'MyCustomFont13',sans-serif;
  max-width: 1200px;
  padding: 0 10px;
  box-sizing: border-box;
}

/* Service Card */
.service-card {
  background: rgba(255, 255, 255, 0.2); /* Glassy transparent effect */
  backdrop-filter: blur(16px);
  border: 1px solid rgba(255, 255, 255, 0.3); /* Subtle border for depth */
  border-radius: 16px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  perspective: 1000px; /* Perspective for rotation */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 350px;
  overflow: hidden;
  position: relative;

}

.service-card:hover {
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.4);
}

/* Flip Animation */
.service-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s ease-in-out;
  font-family: 'MyCustomFont13',sans-serif;
}

.service-card.active .service-card-inner {
  transform: rotateY(180deg);
}

/* Front and Back */
.service-card-front,
.service-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 16px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.service-card-front {
  background: rgba(255, 255, 255, 0.7); /* More glass-like front */
  color: #004d4d; /* Dark teal text */
  font-size: large;
}

.service-card-back {
  background: linear-gradient(135deg, #b3e5fc, #81d4fa);
  color: #4a4a4a; /* White text for contrast */
  transform: rotateY(180deg);
  box-shadow: inset 0 4px 10px rgba(0, 0, 0, 0.3);
}

/* Icon and Text */
.service-icon {
  font-size: 50px;
  margin-bottom: 20px;
}

.service-title,
.service-back-title {
  font-size: 22px;
  font-weight: bold;
  margin: 10px 0;
}

.service-description,
.service-back-text {
  font-size: 16px;
  text-align: center;
  line-height: 1.5;
  margin: 0;
}

/* Responsiveness */
@media (max-width: 768px) {
  .service-container {
    grid-template-columns: 1fr; /* Single column layout */
    gap: 20px;
  }

  .service-card {
    height: 300px;
  }
}
