/* Navbar.css */

.navbar-container {
  background-color: #124278; /* Light gray background */
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow-x: hidden; /* Prevent horizontal scrolling */
  position: sticky; /* Sticky navbar */
  top: 0;
  z-index: 1000; /* Ensure it stays above other content */
  width: 100%; /* Lock full width */
  transition: padding 0.3s ease; /* Smooth padding transition */
  will-change: padding; /* Optimizes for performance */
  font-family: 'MyCustomFont11', sans-serif;
}

.navbar-container.shrink {
  padding-top: 10px; /* Adjust padding instead of height */
}

.nav-item {
  color: #124278; /* Dark blue for nav item */
  font-family: 'MyCustomFont13', sans-serif;
  text-decoration: none;
  font-size: 1.2rem; /* Adjusted font size */
  font-weight: bold;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  will-change: color, transform; /* Optimizes for performance */
  margin: 0 15px; /* Adjust spacing between nav items */
}

.nav-item:hover {
  color: #f57c00; /* Highlight on hover */
  transform: scale(1.1); /* Slight zoom on hover */
}

.nav-links {
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center;
  gap: 20px; /* Consistent spacing between nav items */
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px; /* Adjusted padding */
  height: 150px; /* Initial height of top bar */
  width: 100%; /* Full width to align all items */
  box-sizing: border-box;
  position: relative;
  transition: padding 0.3s ease; /* Smooth padding transition */
  will-change: padding; /* Optimizes for performance */
}

.navbar-container.shrink .top-bar {
  padding: 0px 10px; /* Adjust padding instead of height */
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 0px; /* Space between logos and brand name */
}

.logo {
  height: 150px;
  width: 250px;
  padding-left: 50px;
  cursor: pointer;
  transition: transform 0.3s ease; /* Smooth scaling instead of size change */
  will-change: transform; /* Optimizes for performance */
}

.navbar-container.shrink .logo {
  transform: scale(0.85); /* Scale down instead of resizing */
}

.second-logo {
  height: 150px;
  width: 350px;
  position: absolute;
  left: 50%; /* Center horizontally */
  transform: translateX(-50%);
  cursor: pointer;
  transition: transform 0.3s ease; /* Smooth scaling instead of size change */
  will-change: transform; /* Optimizes for performance */
}

.navbar-container.shrink .second-logo {
  transform: translateX(-50%) scale(0.8); /* Scale down with center alignment */
}

.emergency-container {
  display: flex;
  color: #F5F5F5;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
  transition: transform 0.3s ease; /* Smooth shrinking transition */
  will-change: transform; /* Optimizes for performance */
}

.emergency-title {
  font-family: 'MyCustomFont13', sans-serif;
  color: #F5F5F5;
  font-size: large;
}

.navbar-container.shrink .emergency-container {
  transform: scaleY(0.8); /* Shrink emergency container */
}

.emergency-button {
  background-color: #f57c00; /* Orange button for emergency */
  color: #ffffff; /* White text for contrast */
  padding: 15px 20px;
  margin-top: 10px;
  margin-right: 15px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  will-change: background-color, transform; /* Optimizes for performance */
}

.emergency-button:hover {
  background-color: #d43b3b; /* Darker red on hover */
}

.navbar-contents {
  background-color: #F5F5F5; /* Dark blue for navbar */
  padding: 10px 0;
  width: 100%; /* Lock full width */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease; /* Smooth transform transition */
  will-change: transform; /* Optimizes for performance */
}

.navbar-container.shrink .navbar-contents {
  padding: 8px 0; /* Slightly reduce navbar padding */
}

@media (max-width: 768px) {
  .navbar-container {
    background-color: #124278;
    margin: 0;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
    width: 100%;
    z-index: 1000;
    position: sticky; /* Make navbar sticky */
    top: 0;
  }

  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    flex-wrap: wrap; /* Allow wrapping of items */
    z-index: 1020;
  }

  /* Logo container aligned to the left */
  .logo-container {
    display: flex;
    align-items: center;
    gap: 50%;
    width: 100%; /* Ensure it takes the full width */
    padding-left: -50px; /* Remove padding to ensure no extra space */
    position: relative;
    z-index: 1020;
  }

  /* First logo on the left */
  .logo {
    height: 70px; /* Adjust logo size */
    width: auto;
    margin-left: -18%; /* Ensure no margin on the left */
    position: relative; /* Ensure it stays in the document flow */
    z-index: 1030; /* Higher z-index to ensure it's above the second logo */
    
  }

  /* Second logo centered */
  .second-logo {
    height: 70px;
    width: auto;
    position: absolute; /* Absolute positioning */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust for exact center */
    z-index: 1020; /* Lower z-index to ensure it's below the first logo */
  }

  /* Emergency container moved up */
  .emergency-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #f5f5f5;
    gap: 5px;
    padding: 10px 0;
    width: 100%;
    margin-top: -5px; /* Move up further */
    z-index: 1020;
  }

  .emergency-title {
    font-family: 'MyCustomFont13', sans-serif;
    color: #f5f5f5;
    font-size: 1.2rem; /* Use relative units */
  }

  .emergency-button {
    background-color: #f57c00;
    color: #ffffff;
    padding: 10px 15px; /* Slightly smaller padding for mobile */
    margin-top: 10px;
    border-radius: 10px;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 12px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  /* Hamburger menu styles */
  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 25px;
    cursor: pointer;
    z-index: 1020; /* Ensures it's above the menu */
    
  }

  .hamburger div {
    width: 100%;
    height: 5px;
    background-color:#124278; /* Black hamburger icon */
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
  }

  /* Mobile menu with smooth transition */
  .mobile-menu {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #f5f5f5;
    padding: 20px 0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
  }

  .navbar-contents {
    margin-top: 20px;
  }

  .mobile-menu.show {
    display: flex;
    max-height: 500px; /* Adjust based on content height */
  }

  .nav-item {
    color: #124278;
    font-family: 'MyCustomFont13', sans-serif;
    text-decoration: none;
    font-size: 1.2rem; /* Use relative units */
    font-weight: bold;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
    padding: 10px 20px;
  }

  .nav-item:hover {
    color: #f57c00;
    transform: scale(1.1);
  }

  /* Hamburger icon transforms when clicked */
  .navbar-container.open .hamburger div:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .navbar-container.open .hamburger div:nth-child(2) {
    opacity: 0;
  }

  .navbar-container.open .hamburger div:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
}