/* Testimonials Section */
.testimonials-section {
    background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.8), rgba(175, 238, 238, 0.6));
    padding: 50px 20px;
    text-align: center;
    margin-top: 40px;
    border-radius: 16px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    opacity: 0; /* Start invisible */
    transform: scale(0.8); /* Start slightly smaller */
    transition: transform 0.6s ease, opacity 0.6s ease;
 
  }
  
  .testimonials-section.visible {
    opacity: 1;
    transform: scale(1.05); /* Magnify the entire section slightly */
  }
  
  /* Testimonial Container */
  .testimonial-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 2 cards per row */
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'MyCustomFont11',sans-serif;
  }
  
  /* Testimonial Card */
  .testimonial-card {
    background: rgba(255, 255, 255, 0.9); /* Light opaque background */
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    opacity: 0; /* Start invisible */
    transform: scale(0.8); /* Start slightly smaller */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .testimonial-card:hover {
    transform: translateY(-20px); /* Slight lift effect */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  }
  
  .testimonial-card.animate-card {
    opacity: 1;
    transform: scale(1); /* Magnify the card as it animates into view */
  }
  
  /* Image */
  .testimonial-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  /* Name and Stars */
  .testimonial-name {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .testimonial-stars {
    color: #FFD700; /* Gold stars */
    margin-bottom: 15px;
  }
  
  /* Feedback */
  .testimonial-feedback {
    font-size: 16px;
    font-family: 'MyCustomFont13',sans-serif;
    color: #4a4a4a;
    line-height: 1.5;
  }
  
/* Responsive Design */
@media (max-width: 1024px) {
    .testimonial-container {
      grid-template-columns: repeat(3, 1fr); /* 3 cards per row for medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .testimonial-container {
      grid-template-columns: repeat(2, 1fr); /* 2 cards per row for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .testimonial-container {
      grid-template-columns: 1fr; /* 1 card per row for mobile screens */
    }
  }