/* Info Scroll Container - Glass Effect */
.info-scroll-container {
    display: flex;
    align-items: flex-start;
    justify-content: center; /* Centers the entire component horizontally */
    gap: 20px;
    padding: 40px;
    background: rgba(255, 255, 255, 0.1); /* Light transparent background */
    backdrop-filter: blur(15px); /* Glass blur effect */
    border-radius: 16px;
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3); /* Creates a floating effect */
    margin: 40px auto;
    max-width: 1200px; /* Set a maximum width for the entire component */
    overflow: hidden; /* Hides horizontal scrollbars */
  }
  
  /* Left Panel */
  .left-panel {
    flex: 0 0 30%; /* Fixed width for the left panel */
    position: sticky;
    left:28px;
    top: 75px; /* Sticky position */
    height: fit-content; /* Adjust height to content */
    z-index: 2; /* Ensure it stays above the right panel */
  }
  
  /* Contact Card */
  .contact-card {
    background: rgba(255, 255, 255, 0.5); /* Transparent white background */
    backdrop-filter: blur(20px); /* Glassy blur effect */
    border-radius: 16px;
    color:#124278;
    padding: 40px;
    text-align: center;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Adds depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    font-family: 'MyCustomFont13',sans-serif;
  }
  .contact-card p {
    color: #8B572A;
    margin-bottom: 20%;
  }
  
  .contact-card:hover {
    transform: translateY(-5px); /* Lift effect */
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
  }
  
  .profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  .call-now-btn {
    background: linear-gradient(to right, #ff7e5f, #feb47b); /* Gradient for better visuals */
    color: #fff;
    border: none;
    padding: 12px 25px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin: 20px 0;
    transition: background 0.3s;
    font-family: 'MyCustomFont13'sans-serif;
    text-decoration: none;
  }
  
  .call-now-btn:hover {
    background: linear-gradient(to right, #ff512f, #dd2476); /* Darker gradient on hover */
  }
  
  /* Right Panel */
.right-panel {
    flex: 1;
    max-height: 80vh; /* Restricts height for independent scrolling */
    overflow-y: auto; /* Scroll behavior for the right panel */
    scrollbar-width: none; /* Hides scrollbar in Firefox */
    -ms-overflow-style: none; /* Hides scrollbar in IE */
  }
  
  .right-panel::-webkit-scrollbar {
    display: none; /* Hides scrollbar in Chrome, Safari */
  }
  
  /* Scroll Section (Cards) */
.scroll-section {
    width: 60%; /* Narrower width for the cards */
    height: 510px; /* Taller height for the cards */
    margin: 0 auto; /* Centers the cards horizontally */
     /*background: rgba(255, 255, 255, 0.15); /* Semi-transparent glassy background */
    background: rgba(255, 255, 255, 0.5); 
    backdrop-filter: blur(10px); /* Glassy blur effect */
    border-radius: 16px;
    padding: 20px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Adds depth */
    margin-bottom: 30px; /* Space between cards */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .scroll-section:hover {
    transform: translateY(-15px); /* Lift effect */
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
  }
  
  .scroll-section h2 {
    font-size: 20px;
    font-family: 'MyCustomFont13',sans-serif;
    color: #124278; /* White text for better contrast */
    margin-bottom: 15px;
    text-align: center;
    font-weight: bold;
  }
  
  .scroll-section p {
    font-size: 16px;
    color: #8B572A; /* White text for better contrast */
    line-height: 1.6;
    text-align: justify;
    font-family: 'MyCustomFont7',sans-serif;
  }


/* Image Styling */
.scroll-section img {
    width: 100%; /* Full width for the image */
    height: 200px; /* Fixed height for the image */
    object-fit: cover; /* Ensures the image maintains aspect ratio */
    border-radius: 12px; /* Rounded corners */
    margin-bottom: 15px;
  }
  
  
  /* Responsive Design - Mobile */
/* Responsive Design - Mobile */
@media (max-width: 768px) {
  .info-scroll-container {
      flex-direction: column;
      padding: 10px;
  }

  .left-panel {
      position: static;
      margin-bottom: 10px;
  }

  .scroll-section {
      width: 100%; /* Full width for the cards on mobile */
      height: auto; /* Allow height to adjust based on content */
      padding: 20px; /* Add some padding to ensure the content doesn't touch the edges */
      box-sizing: border-box; /* Ensure padding is included in width calculation */
      margin-bottom: 20px; /* Space between cards */
  }

  .scroll-section h2 {
      font-size: 18px; /* Smaller font size for titles */
  }

  .scroll-section p {
      font-size: 14px; /* Smaller font size for text */
  }
}

/* On Very Small Screens (e.g., 360px) */
@media (max-width: 360px) {
  .scroll-section {
      width: 100%; /* Full width on very small screens */
      padding: 15px; /* Add padding for smaller screens */
  }

  .scroll-section h2 {
      font-size: 16px; /* Further reduce font size for headings */
  }

  .scroll-section p {
      font-size: 12px; /* Further reduce font size for text */
  }
}

  @media only screen and (max-width: 576px) {
    .info-scroll-container {
      margin-top: 160px;
    }
  }